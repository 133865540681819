<template>
	<div class="navbarBox" :class="{'pc':!isMobile(),'m':isMobile()}">
		<div class="topTips">用最便宜的价格在游戏内获得福利礼包</div>
		<div class="tabnav maxBox">
			<div class="logoImg">
				<router-link to="/">
					<!-- <img src="@/assets/images/game_icon.png" /> -->
					长沙奇葩斗斗网络科技有限公司
				</router-link>
			</div>
			<div class="tabBtn">
				<span class="menuBtn" v-if="!showMenu" @click="showMenu = true">
					<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
					  <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
					</svg>
				</span>
				<span class="closeBtn" v-else @click="showMenu = false">
					<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
					  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
					</svg>
				</span>
			</div>
			<div class="tablist" v-if="showMenu || !isMobile()">
				<ul>
					<li :class="{'active':current=='/index'}">
						<router-link to="/index">首页</router-link>
					</li>
					<li :class="{'active':current=='/products'}">
						<router-link to="/products">游戏列表</router-link>
					</li>
					<template v-if="isMobile()">
						<li :class="{'active':current=='/account'}">
							<router-link to="/account/login">登录</router-link>
						</li>
						<li :class="{'active':current=='/account'}">
							<router-link to="/account/register">注册</router-link>
						</li>
					</template>
				</ul>
			</div>
			<!-- <div class="navIcons">
				<router-link to="/cart">
				<span class="i">
					<el-icon><ShoppingCart /></el-icon>
				</span>
				</router-link>
				<span class="i">
					<el-icon>
						<User />
					</el-icon>
				</span>
				<template v-if="!userid">
					<router-link class="l" to="/account/login">登录</router-link>
					<router-link class="l" to="/account/register">注册</router-link>
				</template>
				<template v-else>
					<a class="l" @click="handleLogout">退出登录</a>
				</template>
			</div> -->
		</div>
	</div>
</template>

<script setup>
	import { User,Search,ShoppingCart } from '@element-plus/icons-vue'
	import { isMobile } from '@/utils/common.js'
	import { ref,defineAsyncComponent,onMounted,getCurrentInstance, computed } from 'vue'
	import { useRoute,useRouter,onBeforeRouteUpdate } from 'vue-router'
	import useUserStore from '@/store/modules/user'
	
	const { proxy } = getCurrentInstance();
	const route = useRoute();
	const router = useRouter();
	const current = ref(route.meta.activeMenu);
	const showMenu = ref(false);
	
	onBeforeRouteUpdate((to) => {
		current.value = to.meta.activeMenu;
		showMenu.value = false;
	});
	const userid = computed(() => {
		return useUserStore().userid
	})
	const handleLogout = () => {
		useUserStore().logOut().then(() => {
			router.push('/account/login')
		})
	}
</script>

<style lang="less" scoped>
	.navbarBox{
		width: 100%;background: #FFFFFF;border-bottom: 0.1rem solid rgba(18,18,18,0.08);
		.topTips{
			width: 100%;background: #000;color: #fff;
			text-align: center;padding: 1rem 0;letter-spacing: 0.1rem;
			font-size: 1.3rem;
		}
		.tabnav{
			width: 100%;height: 145px;
			display: grid;padding: 20px 0;box-sizing: border-box;
		}
		&.pc{
			.maxBox{
				max-width: 1200px;margin: 0 auto;
				display: grid;grid-template-columns:auto auto 1fr;column-gap:2rem;align-items: center;
				.tabBtn{
					display: none;
				}
				.tablist{
					ul{
						display: inline-flex;height: 100%;
						li{
							cursor: pointer;color: #292929;font-size: 1.4rem;
							height: 100%;overflow: hidden;
							a{
								display: inline-block;color: rgba(18,18,18,0.75);
								padding: 1.2rem;
							}
							&:hover,&.active{
								a{
									color: rgb(18,18,18);transition: 0.3s;
									text-decoration: underline;text-underline-offset: 0.3rem;
								}
							}
						}
						
					}
				}
				.navIcons{
					display: flex;justify-self: end;align-items: center;
					span.i{
						display: inline-flex;width: 4.4rem;height: 4.4rem;
						align-items: center;justify-content: center;
						color: rgba(18,18,18,0.75);cursor: pointer;
						/deep/ .el-icon{
							font-size: 22px;
						}
						&:hover,&.active{
							color: rgb(18,18,18);transition: 0.3s;
							text-decoration: underline;text-underline-offset: 0.3rem;
							/deep/ .el-icon{
								font-size: 24px;
							}
						}
					}
					a.l{
						display: inline-flex;padding: 0 1rem;height: 1.5rem;
						align-items: center;justify-content: center;font-size: 1.5rem;
						color: rgba(18,18,18,0.75);cursor: pointer;
						&:hover{
							color: rgb(18,18,18);transition: 0.3s;
							text-decoration: underline;text-underline-offset: 0.3rem;
						}
						&:last-child{
							border-left: 1px solid rgba(18,18,18,0.3);
						}
					}
					
				}
				.logoImg{
					height: 100%;width: 200px;position: relative;
					a{
						display: inline-block;font-size: 12px;margin-top: 0;
						padding: 0.75rem;box-sizing: border-box;
						position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);
					}
					img{
						max-width: 75px;
					}
				}
			}
		}
		&.m{
			.tabnav{
				width: 100%;height: 85px;
			}
			.maxBox{
				position: relative;
				.tabBtn{
					position: absolute;top: 30px;right: 20px;
				}
				.tablist{
					position: fixed;z-index: 1111;background: #fff;
					top: 123.5px;left: 0;width: 100%;height: calc(100vh - 123.5px);
					ul{
						width: 100%;
						li{
							width: 100%;padding: 20px 0;text-align: center;
							border-bottom: 1px solid #f5f5f5;color: #303030;
							a{
								display: inline-block;color: #292929;
							}
							&.active,&:hover{
								a{
									color: #ff5a00;transition: 0.3s;
								}
							}
						}
					}
				}
				.logoImg{
					width: 220px;height: 100%;
					a{
						display: inline-block;font-size: 0;height: 100%;
					}
					img{
						max-width: 50px;margin-left: 16px;
						// max-width: 100%;margin-top: 10px;
					}
				}
				.navIcons{display: none;}
			}
		}
	}
</style>
